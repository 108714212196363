<template>
    <div>
        <h1>CHART</h1>
        <router-link to="/Home">Go to Home</router-link>
        <div style="margin-top:50px;" class="centered">
            <div  style="width:600px;">
                <Highcharts :options="options" ref="highcharts"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods : {
        setChartData(){
            this.options = {
                chart: {
                    polar: true,
                    type: 'column'
                },
                title: {
                    text: 'Wind rose for South Shore Met Station, Oregon'
                },
                subtitle: {
                    text: 'Source: or.water.usgs.gov'
                },
                pane: {
                    size: '85%'
                },
                legend: {
                    align: 'right',
                    verticalAlign: 'top',
                    y: 100,
                    layout: 'vertical'
                },
                xAxis: {
                    tickmarkPlacement: 'on',
                    // type:'category',
                    categories:this.categories
                },
                yAxis: {
                    min: 0,
                    endOnTick: false,
                    showLastLabel: true,
                    title: {
                    text: 'Frequency (%)'
                    },
                    labels: {
                    formatter: function () {
                        return this.value + '%';
                    }
                    },
                    reversedStacks: false
                },                              
                tooltip: {
                    valueSuffix: '%'
                },
                series: this.chartDatas,
                plotOptions: {
                    series: {
                        stacking: 'normal',
                        shadow: false,
                        groupPadding: 0,
                        pointPlacement: 'on'
                    }
                }
            }
        }
    },
    data () {
        return{
            categories : ['N','NNE','NE','ENE','E','ESE','SE','SSE','S','SSW','SW','WSW','W','WNW','NW','NNW'],
            chartDatas:[],
            options : {}// Option
        }
    },
    mounted () {
        this.chartDatas = [
            {
                name:'< 0.5 m/s',
                type:'column',
                data:[
                    1.81,0.62,0.82,0.59,0.62,1.22,1.61,2.04,2.66,2.96,2.53,1.97,1.64,1.32,1.58,1.51
                ]
            },
            {
                name:'0.5-2 m/s',
                type:'column',
                data:[
                    1.78,1.09,0.82,1.22,2.20,2.01,3.06,3.42,4.74,4.14,4.01,2.66,1.71,2.4,4.28,5.0
                ]
            },
            {
                name:'2-4 m/s',
                type:'column',
                data:[
                    0.16,0.0,0.07,0.07,0.49,1.55,2.37,1.97,0.43,0.26,1.22,1.97,0.92,0.99,1.28,1.32
                ]
            },
            {
                name:'4-6 m/s',
                type:'column',
                data:[
                    0.0,0.0,0.0,0.0,0.0,0.3,2.14,0.86,0.0,0.0,0.49,0.79,1.45,1.61,0.76,0.13
                ]
            },
            {
                name:'6-8 m/s',
                type:'column',
                data:[
                    0.0,0.0,0.0,0.0,0.0,0.13,1.74,0.53,0.0,0.0,0.13,0.3,0.26,0.33,0.66,0.23
                ]
            },
            {
                name:'8-10 m/s',
                type:'column',
                data:[
                    0.0,0.0,0.0,0.0,0.0,0.0,0.39,0.49,0.0,0.0,0.0,0.0,0.1,0.0,0.69,0.13
                ]
            },
            {
                name:'> 10 m/s',
                type:'column',
                data:[
                    0.0,0.0,0.0,0.0,0.0,0.0,0.13,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.03,0.07
                ]
            },
            // {
            //     name:'Total',
            //     type:'column',
            //     data:[
            //         3.75,1.71,1.71,1.88,3.32,5.20,11.45,9.31,7.83,7.37,8.39,7.7,6.09,6.64,9.28,8.39
            //     ]
            // }
        ],
        this.setChartData();
    }
}
</script>